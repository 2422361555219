<template>
  <div class='framercommon' @click='$router.push(`/framerdetailtoapp/${taskItem.id}?t=${token}`)'>
    <!--  App创作者任务公用组件  -->
    <div class='framer-top'>
      <div class='img' :style='{background: "url("+taskItem.cover+") no-repeat center center", backgroundSize: "cover"}'></div>
      <div class='content'>{{taskItem.title}}</div>
    </div>
    <div class='framer-bottom'>
      <div class='award'>奖励总额：{{ rewardType }}</div>
      <div class='activity'>
        <div class='type'>体裁：{{ type[taskItem.genre] }}</div>
        <div class='end' v-if='taskItem.day === 0'>已结束</div>
        <div class='time' v-else>{{taskItem.day > 0 ? '还剩' : ''}}{{Math.abs(taskItem.day)}}{{taskItem.day > 0 ? '天结束' : '天后开始'}}</div>
      </div>
    </div>
    <div v-show='bol' class='framer-line'></div>
  </div>
</template>

<script>
export default {
  name: 'FramerCommon',
  computed: {
    rewardType() {
      return this.taskItem.rewardType === 0 ? this.taskItem.rewardAmount + '鱼仔' : this.taskItem.rewardType === 1 ? this.taskItem.rewardAmount + '罐头' : this.taskItem.rewardType === 2 ? '现金' : '礼物'
    }
  },
  data() {
    return {
      type: Object.freeze({1: '文章', 2: '视频', 3: '动态', 4: '不限'})
    }
  },
  props: {
    taskItem: {
      type: Object,
      default: () => {}
    },
    bol: {
      type: Boolean,
      default: true
    },
    token: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang='less'>
.framercommon {
  width: 100%;
  .framer-top {
    display: flex;
    height: 78px;
    margin-bottom: 8px;
    .img {
      flex-shrink: 0;
      width: 118px;
      height: 78px;
      margin-right: 16px;
      border-radius: 6px;
    }
    .content {
      flex-shrink: 1;
      width: 100%;
      height: 78px;
      line-height: 25px;
      color: #222;
      font-size: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  .framer-bottom {
    height: 33px;
    background: #FAFAFA;
    border-radius: 6px;
    font-size: 12px;
    display: flex;
    box-sizing: border-box;
    padding: 0 8px;
    .award {
      flex-shrink: 0;
      min-width: 118px;
      color: #666666;
      margin-right: 8px;
      line-height: 33px;
    }
    .activity {
      width: 100%;
      height: 33px;
      line-height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .type {
        color: #666;
      }
      .time {
        height: 25px;
        line-height: 25px;
        background: #FCF2F0;
        border-radius: 6px;
        color: #F7321C;
        text-align: center;
        padding: 0 8px;
      }
      .end {
        width: 52px;
        height: 25px;
        line-height: 25px;
        background: #DDDDDD;
        border-radius: 6px;
        color: #222222;
        text-align: center;
      }
    }
  }
  .framer-line {
    width: 100%;
    height: 2px;
    background: #F0EDED;
    margin: 12px 0 20px 0;
  }
}
</style>
